"use client";

import { Nav } from "@/components/nav";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/lib/utils";
import { CirclePlus, Globe, History, User } from "lucide-react";
import { Suspense, useEffect, useState } from "react";
import { getLocalStorageItem, setLocalStorageItem } from "../dashboard/demo";
import { available_Languages, langType } from "../dashboard/state";
import AuthenticationForm from "./loginform";
import { pt } from "date-fns/locale";
import { getPreferredLang } from "../dashboard/clientLayout";
import { useSearchParams } from "next/navigation";

const LanguagePicker = ({
  lang,
  setLang,
}: {
  lang: langType;
  setLang: (v: langType) => void;
}) => {
  return (
    <div className=" w-fit">
      <Popover>
        <PopoverTrigger>
          <div className=" text-sm p-2 rounded hover:bg-neutral-100 hover:text-omnilex_neutral-1000 h-9 border flex flex-row items-center gap-1">
            <Globe className="h-3 w-3" />
            {lang.toUpperCase()}
          </div>
        </PopoverTrigger>
        <PopoverContent side="right" className=" w-fit mb-2">
          <div>
            {available_Languages.map((l, idx) => {
              return (
                <div
                  key={idx}
                  className={cn(
                    " cursor-pointer hover:underline rounded px-2",
                    lang === l.code ? " bg-omnilex_neutral-200" : ""
                  )}
                  onClick={() => {
                    setLang(l.code);
                  }}
                >
                  {l.name}
                </div>
              );
            })}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

const dictionary = {
  welcomeMessage: {
    en: "AI-powered legal advice for modern legal professionals",
    de: "KI-gestützte Rechtsberatung für moderne JuristInnen",
    fr: "Conseil juridique assisté par IA pour les juristes modernes",
    it: "Consulenza legale basata sull'IA per giuristi moderni",
    pt: "Conselho jurídico assistido por IA para profissionais do direito modernos",
  },
  loginTitle: {
    en: "Login with existing account or try the demo",
    de: "Login mit existierendem Account oder Demo ausprobieren",
    fr: "Connexion avec un compte existant ou essayez la démo",
    it: "Accedi con un account esistente o prova la demo",
    pt: "Faça login com uma conta existente ou experimente a demonstração",
  },

  loginTitleLogin: {
    en: (
      <div className="flex flex-col items-start text-left">
        <div>Welcome Back</div>
        <div className="text-sm font-normal text-neutral-500">
          Use Omnilex to complete your legal research with ease.
        </div>
      </div>
    ),
    de: (
      <div className="flex flex-col items-start text-left">
        <div>Willkommen zurück</div>
        <div className="text-sm font-normal text-neutral-500">
          Nutze Omnilex und erledige juristische Recherchen mühelos.
        </div>
      </div>
    ),
    fr: (
      <div className="flex flex-col items-start text-left">
        <div>Bon retour</div>
        <div className="text-sm font-normal text-neutral-500">
          Utilisez Omnilex pour mener à bien vos recherches juridiques en
          toute simplicité.
        </div>
      </div>
    ),
    it: (
      <div className="flex flex-col items-start text-left">
        <div>Bentornato</div>
        <div className="text-sm font-normal text-neutral-500">
          Usa Omnilex per completare le tue ricerche legali con facilità.
        </div>
      </div>
    ),
    pt: (
      <div className="flex flex-col items-start text-left">
        <div>Bem-vindo de volta</div>
        <div className="text-sm font-normal text-neutral-500">
          Use o Omnilex para concluir sua pesquisa jurídica com facilidade.
        </div>
      </div>
    ),
  },

  loginTitleRegister: {
    en: (
      <div className="flex flex-col items-start text-left">
        <div>Create an Omnilex account</div>
        <div className="text-sm font-normal text-neutral-500">
          Sign up to start doing legal research with ease.
        </div>
      </div>
    ),
    de: (
      <div className="flex flex-col items-start text-left">
        <div>Omnilex Konto erstellen</div>
        <div className="text-sm font-normal text-neutral-500">
          Registriere dich und erledige juristische Recherchen mühelos.
        </div>
      </div>
    ),
    fr: (
      <div className="flex flex-col items-start text-left">
        <div>Créer un compte Omnilex</div>
        <div className="text-sm font-normal text-neutral-500">
          Inscrivez-vous pour commencer à effectuer des recherches juridiques 
          en toute simplicité.
        </div>
      </div>
    ),
    it: (
      <div className="flex flex-col items-start text-left">
        <div>Crea un account Omnilex</div>
        <div className="text-sm font-normal text-neutral-500">
          Iscriviti per iniziare a fare ricerche legali con facilità.
        </div>
      </div>
    ),
    pt: (
      <div className="flex flex-col items-start text-left">
        <div>Criar uma conta Omnilex</div>
        <div className="text-sm font-normal text-neutral-500">
          Inscreva-se para começar a fazer pesquisas jurídicas com facilidade.
        </div>
      </div>
    ),
  },
  signInButton: {
    en: "Sign In with ",
    de: "Anmelden mit ",
    fr: "Se connecter avec ",
    it: "Accedi con ",
    pt: "Entrar com ",
  },
  signUpButton: {
    en: "Sign Up with",
    de: "Registrieren mit",
    fr: "S'inscrire avec",
    it: "Iscriviti con",
    pt: "Inscrever-se com",
  },
  noAccountText: {
    en: "No account?",
    de: "Kein Konto?",
    fr: "Pas de compte?",
    it: "Nessun account?",
    pt: "Sem conta?",
  },
  demoLinkText: {
    en: "Try demo",
    de: "Demo ausprobieren",
    fr: "Essayer la démo",
    it: "Prova la demo",
    pt: "Experimente a demonstração",
  },
  continueButton: {
    en: "Continue",
    de: "Weiter",
    fr: "Continuer",
    it: "Continua",
    pt: "Continuar",
  },
  dontShowMessage: {
    en: "Don't show message next time",
    de: "Diese Nachricht beim nächsten Mal nicht anzeigen",
    fr: "Ne plus afficher ce message",
    it: "Non mostrare più questo messaggio",
    pt: "Não mostrar esta mensagem na próxima vez",
  },
  cookieSettings: {
    en: "Cookie settings",
    de: "Cookie-Einstellungen",
    fr: "Paramètres des cookies",
    it: "Impostazioni dei cookie",
    pt: "Configurações de cookies",
  },
  cookieBannerMessage: {
    en: "We use cookies to remember your preferences, like language, and to help us improve by understanding how people use our website.",
    de: "Wir verwenden Cookies, um uns Ihre Einstellungen wie die Sprache zu merken und um uns zu helfen, indem wir verstehen, wie unsere Website genutzt wird.",
    fr: "Nous utilisons des cookies pour mémoriser vos préférences, comme la langue, et pour nous aider à comprendre comment notre site est utilisé.",
    it: "Utilizziamo i cookie per ricordare le tue preferenze, come la lingua, e per aiutarci a capire come viene utilizzato il nostro sito web.",
    pt: "Usamos cookies para lembrar suas preferências, como idioma, e para nos ajudar a melhorar, entendendo como as pessoas usam nosso site.",
  },
  allowAll: {
    en: "Allow All",
    de: "Alle zulassen",
    fr: "Tout autoriser",
    it: "Permetti tutto",
    pt: "Permitir tudo",
  },
  userPreferences: {
    en: "User Preferences",
    de: "Benutzereinstellungen",
    fr: "Préférences utilisateur",
    it: "Preferenze utente",
    pt: "Preferências do usuário",
  },
  usageAndMonitoring: {
    en: "Usage and Bug Monitoring",
    de: "Nutzung und Fehlerüberwachung",
    fr: "Surveillance de l'utilisation et des bugs",
    it: "Monitoraggio dell'utilizzo e dei bug",
    pt: "Monitoramento de uso e bugs",
  },
  didyouknow: {
    en: "Did you know?",
    de: "Schon gewusst?",
    fr: "Saviez-vous que?",
    it: "Sapevi che?",
    pt: "Você sabia?",
  },
  didyouknowText: {
    en: "Omnilex is the leading AI for legal research in Switzerland. Some organizations that use Omnilex daily:",
    de: "Omnilex ist die führende KI für die Recherche im Schweizer Recht. Einige Organisationen, die Omnilex täglich nutzen:",
    fr: "Omnilex est le principal IA pour la recherche juridique en Suisse. Certaines organisations qui utilisent Omnilex quotidiennement:",
    it: "Omnilex è il principale AI per la ricerca legale in Svizzera. Alcune organizzazioni che utilizzano Omnilex quotidianamente:",
    pt: "Omnilex é a principal IA para pesquisa jurídica na Suíça. Algumas organizações que usam o Omnilex diariamente:",
  },
  alreadyhaveanaccount: {
    en: "Already have an account?",
    de: "Bereits ein Konto?",
    fr: "Déjà un compte?",
    it: "Hai già un account?",
    pt: "Já tem uma conta?",
  },
  donthaveanaccountyet: {
    en: "Don't have an account yet?",
    de: "Noch kein Konto?",
    fr: "Pas encore de compte?",
    it: "Non hai ancora un account?",
    pt: "Ainda não tem uma conta?",
  },
};

const mockupBg = () => {
  return (
    <div className="absolute top-0 h-screen w-screen -z-20">
      {/* Blurred and non-clickable overlay */}
      <div className="absolute inset-0 bg-white/30 blur-sm backdrop-blur-md pointer-events-none">
        <div className="absolute h-screen w-screen top-0 flex flex-row opacity-50">
          <div className="bg-omnilex_neutral-1000 border-r border-black box-border px-2 flex md:flex-col flex-wrap text-white justify-between">
            <div className="flex md:flex-col flex-row">
              <div className="h-[52px] items-center justify-center mx-4 md:m-0 hidden md:flex">
                OL
              </div>
              <Nav
                links={[
                  {
                    title: "",
                    label: <></>,
                    icon: <CirclePlus className="h-4 w-4"/>,
                    href: "/login",
                    onClick: () => {},
                  },
                ]}
                isCollapsed={false}
              />
              <Nav
                isCollapsed={false}
                links={[
                  {
                    title: "",
                    label: <></>,
                    icon: <History className="h-4 w-4"/>,
                    href: "/dashboard/history",
                  },
                ]}
              />
            </div>

            <div className="flex md:flex-col flex-row">
              <Nav
                links={[
                  {
                    title: "",
                    label: <></>,
                    icon: <User className="h-4 w-4"/>,
                    href: "",
                    onClick: () => {},
                  },
                ]}
                isCollapsed={false}
              />

              <div className="h-[52px] items-center justify-center mx-4 md:m-0 hidden md:flex">
                DE
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 w-full h-full">
            <div className="border-r bg-neutral-200 border-neutral-200">
              <div className="border-b border-neutral-700  p-4 font-medium">
                Question
              </div>
              <div className="p-6">
                <div className="">
                  <Textarea
                    value={"This is a queytin fdksjk jk sdfjk sfdjk sfd ?"}
                    onChange={() => {}}
                  />
                </div>

                <div className=" font-medium py-3">Filter Settings</div>

                <div className="flex flex-col gap-2">
                  <div className="bg-white border rounded p-4">
                    <div className=" flex flex-row gap-3">
                      {" "}
                      <div className=" bg-green-600 h-6 w-6 rounded grid place-items-center">
                        A
                      </div>{" "}
                      Title Dsdfsoc
                    </div>
                    <div className="text-sm">
                      {" "}
                      sdkoakosda sdokakosda kosadkosdak akd sookasdoadskosad
                      kasodk aso dkaodkaokdsk oaskodkoasdoksako doksadkoako
                      sdoak dsok aksod kao sdk oako sdko adko kao sdok
                      aoksdokasokdkoasdko a kodskoasdkoakosd koaskodok asdokaok
                      sdkoaskdokoasdk oakodkoadoka oksd
                    </div>
                  </div>

                  <div className="bg-white border rounded p-4">
                    <div className=" flex flex-row gap-3">
                      {" "}
                      <div className=" bg-yellow-600 h-6 w-6 rounded grid place-items-center">
                        A
                      </div>{" "}
                      Title Doc
                    </div>
                    <div className="text-sm">
                      {" "}
                      sdkoakosda sdokakosda kosaddhsjf ijfs fdsij fdsij
                      ifsjdikosdak akd sookasdoadskosad kasodk aso dkaodkaokdsk
                      oaskodkoasdoksako doksadkoako sdoak dsok aksod kao sdk
                      oako sdko adko kao sdok aoksdokasokdkoasdko a
                      kodskoasdkoakosd koaskodok asdokaok sdkoaskdokoasdk
                      oakodkoadoka oksd
                    </div>
                  </div>

                  <div className="bg-white border rounded p-4">
                    <div className=" flex flex-row gap-3">
                      {" "}
                      <div className=" bg-yellow-600 h-6 w-6 rounded grid place-items-center">
                        A
                      </div>{" "}
                      Title Doc
                    </div>
                    <div className="text-sm">
                      {" "}
                      sdkoakosda sdokakosda kosadkosdak akd sookasdoadskosad
                      kasodk aso dkaodkaokdsk oaskodkoasdoksako doksadkoako
                      sdoak dsok aksod kao sdk oako sdko adko kao sdok
                      aoksdokasokdkoasdko a kodskoasdkoakosd koaskodok asdokaok
                      sdkoaskdokoasdk oakodkoadoka oksd
                    </div>
                  </div>

                  <div className="bg-white border rounded p-4">
                    <div className=" flex flex-row gap-3">
                      {" "}
                      <div className=" bg-green-600 h-6 w-6 rounded grid place-items-center">
                        A
                      </div>{" "}
                      Titlsdfsdfe Doc
                    </div>
                    <div className="text-sm">
                      {" "}
                      sdkoakosda sdokakosda kosadkosdak akd sookasdoadskosad
                      kasodk aso dkaodkaokdsk oaskodkoasdoksako doksadkoako
                      sdoak dsok aksod kao sdk oako sdko adko kao sdok
                      aoksdokasokdkoasdko a kodskoasdkoakosd koaskodok asdokaok
                      sdkoaskdokoasdk oakodkoadoka oksd
                    </div>
                  </div>

                  <div className="bg-white border rounded p-4">
                    <div className=" flex flex-row gap-3">
                      {" "}
                      <div className=" bg-green-600 h-6 w-6 rounded grid place-items-center">
                        A
                      </div>{" "}
                      Title Doc
                    </div>
                    <div className="text-sm">
                      {" "}
                      sdkoakosda sdokakosda kosadkosdak akd sooka dkfj jfsdjfsdi
                      jij isdfji jsfijfjisjfsjdi sdoadskosad kasodk aso
                      dkaodkaokdsk oaskodkoasdoksako doksadkoako sdoak dsok
                      aksod kao sdk oako sdko adko kao sdok aoksdokasokdkoasdko
                      a kodskoasdkoakosd koaskodok asdokaok sdkoaskdokoasdk
                      oakodkoadoka oksd
                    </div>
                  </div>

                  <div className="bg-white border rounded p-4">
                    <div className=" flex flex-row gap-3">
                      {" "}
                      <div className=" bg-blue-600 h-6 w-6 rounded grid place-items-center">
                        A
                      </div>{" "}
                      Titlesdf Doc
                    </div>
                    <div className="text-sm">
                      {" "}
                      sdkoakosda sdokakosda kosadkosdak akd sookasdoadskosad
                      kasodk aso dkaodkaokdsk oaskodkoasdoksako doksadkoako
                      sdoak dsok aksod kao sdk oako sdko adko kao sdok
                      aoksdokasokdkoasdko a kodskoasdkoakosd koaskodok asdokaok
                      sdkoaskdokoasdk oakodkoadoka oksd
                    </div>
                  </div>

                  <div className="bg-white border rounded p-4">
                    <div className=" flex flex-row gap-3">
                      {" "}
                      <div className=" bg-yellow-600 h-6 w-6 rounded grid place-items-center">
                        A
                      </div>{" "}
                      Titlfsdfsde Doc
                    </div>
                    <div className="text-sm">
                      {" "}
                      sdkoakosda sdokakosda kosadkosdak akd sookasdoadskosad
                      kasvsivsc jvsic ivscj viodk aso dkaodkaokdsk
                      oaskodkoasdoksako doksadkoako sdoak dsok aksod kao sdk
                      oako sdko adko kao sdok aoksdokasokdkoasdko a
                      kodskoasdkoakosd koaskodok asdokaok sdkoaskdokoasdk
                      oakodkoadoka oksd
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" ">
              <div className="border-b border-neutral-700 p-4 font-medium">
                Answer
              </div>
              <div className="p-4"></div>

              <div className=" flex flex-col gap-6 p-6">
                <div>
                  Oui, vous pouvez toujours faire une réclamation{" "}
                  <span className=" bg-omnilex_neutral-300">xxx</span> pour votre
                  accident survenu pendant la pause déjeuner, à condition que
                  l'accident se soit produit sur le lieu de travail ou dans la
                  zone de danger liée à votre activité professionnelle. Selon
                  l'article 7 alinéa 1 lettre b de la Loi sur
                  l'assurance-accidents (LAA), un accident est considéré comme
                  professionnel lorsqu'il survient "au cours d’une interruption
                  de travail, de même qu’avant ou après le travail, lorsqu’il se
                  trouve, à bon droit, au lieu de travail ou dans la zone de
                  danger liée à son activité professionnelle" (Art. 7 al. 1 let.
                  b LAA).
                </div>
                <div className="bg-omnilex_neutral-50 p-4 border border-omnilex_neutral-500 rounded">
                  Ainsi, si l'accident s'est produit sur le lieu de travail ou{" "}
                  <span className=" bg-omnilex_neutral-300">xxx</span> dans une zone
                  directement liée à votre activité professionnelle, il peut
                  être déclaré comme accident professionnel. En revanche, si
                  l'accident s'est produit en dehors de ces zones, par exemple
                  en dehors du site de la{" "}
                  <span className=" bg-omnilex_neutral-300">xxx</span> société, il
                  pourrait être considéré comme un accident non professionnel.
                </div>

                <div>
                  Il est également important de noter que les travailleurs à
                  temps partiel travaillant au moins huit heures par semaine
                  sont couverts contre les accidents non professionnels (Art. 13
                  al. 1 OLAA).
                </div>

                <div>
                  Pour les éviter des frais juridiques,
                  <span className=" bg-omnilex_neutral-300">xxx</span> assurez-vous de
                  documenter<span className=" bg-omnilex_neutral-300">xxx</span>{" "}
                  précisément les circonstances de l'accident et de consulter
                  votre employeur ou votre assureur pour plus de détails sur la
                  couverture d'assurance applicable à votre cas.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function AuthenticationPageWrapper() {
  return (
    <Suspense fallback={<div></div>}>
      <AuthenticationPage />
    </Suspense>
  );
}

export function AuthenticationPage() {
  const searchParams = useSearchParams();
  const sourceParam = searchParams.get("source") ?? "";

  const [lang, setLang] = useState<langType>(() => {
    return getLocalStorageItem("preferredLang", getPreferredLang("de"));
  });
  const [loginform, setLoginform] = useState(<></>);
  const [isnewuser, setIsnewuser] = useState(() => {
    return getLocalStorageItem("hasAlreadyLoginOnce", "no") === "no";
  });

  useEffect(() => {
    setLocalStorageItem("preferredLang", lang);
    AuthenticationForm({
      text: isnewuser
        ? dictionary.signUpButton[lang]
        : dictionary.signInButton[lang],
      isnewuser: isnewuser,
      source: sourceParam,
    }).then((f) => {
      setLoginform(f);
    });
  }, [lang, isnewuser]);

  const [userPrefCookie, setUserPrefCookie] = useState(true);
  const [monitoringCookie, setMonitoringCookie] = useState(true);

  useEffect(() => {
    setUserPrefCookie(getLocalStorageItem("userPrefCookie", "yes") === "yes");
    setMonitoringCookie(
      getLocalStorageItem("monitoringCookie", "yes") === "yes"
    );
  }, []);

  useEffect(() => {
    if (window.clarity) {
      window.clarity("consent", monitoringCookie);
    }
  }, [userPrefCookie, monitoringCookie]);

  return (
    <>
      {mockupBg()}
      <div className="p-1 w-screen h-screen grid place-items-center">
        <div className="max-h-[800px] max-w-[1600px] flex place-items-center shadow-2xl border rounded-md overflow-hidden bg-omnilex_neutral-1000">
          {/**Login */}
          <div className="p-1 sm:p-8 bg-white grid place-items-center min-h-full">
            <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
              <div className="flex flex-row justify-between w-full">
                <div className="font-medium flex flex-row gap-2 items-end"> 
                  
                  <img src="./logo/Omnilex_Logo_Dark.svg" className=" h-6 "
                    alt="Omnilex Logo"
                  /> </div>

                <div>
                  {" "}
                  <LanguagePicker lang={lang} setLang={setLang} />
                </div>
              </div>

              <div>
                <div className="flex flex-col space-y-2 text-center">
                  <h1 className="text-2xl font-bold tracking-tight">
                    {isnewuser ? (
                      <>{dictionary.loginTitleRegister[lang]}</>
                    ) : (
                      <>{dictionary.loginTitleLogin[lang]}</>
                    )}
                  </h1>
                  <div className=" min-h-[320px] py-9">{loginform}</div>

                  
                  <div
                    className="text-sm text-omnilex_neutral-600 cursor-pointer hover:underline"
                    onClick={() => {
                      setIsnewuser(!isnewuser);
                    }}
                  >
                    {isnewuser ? (
                      <> {dictionary.alreadyhaveanaccount[lang]} </>
                    ) : (
                      <> {dictionary.donthaveanaccountyet[lang]} </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/**Logos */}
          <div className="relative hidden max-w-[400px] min-h-full flex-col sm:p-10 lg:flex dark:border-r text-white bg-omnilex_neutral-1000">
            <div className="flex flex-col gap-6 justify-between h-full overflow-hidden m-6">
              <div className="h-full grid">
                <div className="">
                  <div className=" font-medium">
                    {dictionary.didyouknow[lang]}
                  </div>
                  <div className=" py-2 text-sm">
                    {dictionary.didyouknowText[lang]}
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-2 w-full justify-between filter grayscale brightness-200">
                <div className="p-2 rounded h-[70px]">
                  <img
                    src="/logo/federal.svg"
                    className="h-full w-full object-contain filter grayscale"
                  />
                </div>
                <div className="p-2 rounded h-[80px]">
                  <img
                    src="/logo/vaudoise.png"
                    className="h-full w-full object-contain filter grayscale invert"
                  />
                </div>
                <div className="p-2 rounded h-[80px]">
                  <img
                    src="/logo/swisslegal.png"
                    className="h-full w-full object-contain filter grayscale invert"
                  />
                </div>
                <div className="p-4 rounded h-[70px]">
                  <img
                    src="/logo/dnp.svg"
                    className="h-full w-full object-contain filter grayscale"
                  />
                </div>
                <div className="p-4 rounded h-[65px]">
                  <img
                    src="/logo/legalpartner.png"
                    className="h-full w-full object-contain filter grayscale invert"
                  />
                </div>
                <div className="rounded h-[75px] p-6">
                  <img
                    src="/logo/dextra.svg"
                    className="h-full w-full object-contain filter grayscale invert"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
